.page-not-found {
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.page-not-found a {
  padding: 12px 16px;
  border-radius: 24px;
  display: inline-block;
  background-color: #009C5A;
  color: #fff;
  box-shadow: 4px 6px 32px rgba(29, 33, 31, 0.32), inset 1px 1px 2px rgba(0, 158, 105, 0.2), inset -1px -1px 2px rgba(0, 158, 105, 0.2);
  font-family: "Roboto";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  cursor: pointer;
}