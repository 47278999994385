@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Monteserrat/Montserrat-Regular.eot');
  src: url('./fonts/Monteserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Monteserrat/Montserrat-Regular.woff2') format('woff2'),
      url('./fonts/Monteserrat/Montserrat-Regular.woff') format('woff'),
      url('./fonts/Monteserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Monteserrat/Montserrat-Bold.woff');
  src: url('./fonts/Monteserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Monteserrat/Montserrat-Bold.woff2') format('woff2'),
      url('./fonts/Monteserrat/Montserrat-Bold.woff') format('woff'),
      url('./fonts/Monteserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Monteserrat/Montserrat-SemiBold.woff');
  font-weight: 600;
}
