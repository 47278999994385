.channelContent-container {
    background: #00A4650F;
    padding: 1.5em 1em 1.5em 1em; 
    border-radius: 6px; 
    margin-top: 2em;
}

.channelContent-description {
    margin-bottom: 20px;
    font-size: 14px;
}

.channelContent-tile {
    margin-right: 2em;
}
@media (max-width:1024px) {
    .channelContent-tile{
        margin-right: 0px;
    }
}

.channelDetailsHeader-container {
    background-color: #00A4651A;
    width: 100%; 
    padding: 43px 24px 43.59px 24px;
}

.channelDetailsHeader-main {
    min-height: 100px;
    max-width: 1140px; 
    margin: 0 auto;
}

.channelDetailsHeader-logo {
    width: 149.48px;
    height: 122.41px;
    object-fit: cover;
    overflow: hidden;
    float: left;
    margin: 0 15px 0 0;
}

.channelDetailsHeader-name {
    font-size: 36px;
}

.channelDetailsHeader-description {
    font-size: 16px; 
    font-family: roboto;
}

.channel-list-header {
    /* position: absolute; */
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 230px;
    height: 54px;
    display: flex;
    align-items: center;
    border-radius:0px 0px 50px 0px;
    background: #007D64;
    color: #FFFFFF;
    margin-bottom: 1em;
}

.channel-content-margin {
    margin: 0;
}

.channel-content-div {
    display: flex;
  }
  
.channelContent-tile {
   flex-basis: 30%;
   margin: 2%;
}
  
.partner-content-detail {
    background-color: #00A4651A;
    width: 100%;
    padding: 24px
}

.channel-list-div {
    flex-basis: calc(20% - 25px);
    margin: 18px 12px;
}

.channel-para-subscribed {
    color: #73726c;
    margin-top: 5px;
}

.channel-list-view {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin: 18px 0 0;   
}

.channel-heading-container {
    display: flex;
    flex-direction: row;
}


@media only screen and (max-width: 995px) {
    .channelContent-description {
        margin: 8px 0 15px;;
        font-size: 12px;
    }

    .channel-list-div {
        margin: 30px;
    }

    .channel-card-tile {
        margin-right: 0;
    }

    .channelContent-tile:nth-child(5) {
        display: none;
    }
}

@media screen and (max-width: 530px) {
    .channel-list-view {
        margin-left: 8px;
        width: auto;
    }

    .channel-list-div {
        flex-basis: calc(45% - 22px);
        margin: 12px 18px;
    }

    .channel-content-div {
        column-gap: 38px;
        display: flex;
        flex-flow: row wrap;
        margin-left: 8px;
    }

    .channel-content-div:nth-child(2){
        margin-right: 90px;
    }

    .channelContent-tile {
        flex-basis: calc(50% - 30px);
        margin: 1%;
    }
}
