.full-paage-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
}

.full-page-loader-container {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% + 5px);
  transform: translate(-50%, -50%);
}

.full-page-loader-container span {
  margin: 20px 0;
  display: block;
  font-size: 20px;
  color: #fff;
}

.custom-loader {
  border: 6px solid #f3f3f3;
  margin: 0 auto;
  border-radius: 50%;
  border-top: 6px solid #424242;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
