.iRISHI span {
    font-size: 15px !important;
    color: grey;
}

.iRISHI {
    padding: 0 !important;
}

.cqaNcS {
    margin: 2px !important;
    padding: 0.3rem !important;
}

.bPcRQP {
    margin: -3px !important;
    position: relative !important;
}

[readonly='readonly'] {
    pointer-events: none;
 }

 @media only screen and (min-width: 600px){
    canvas {
        width: 75% !important;
    }
 }

 canvas {
    border: solid grey thick;
 }

 .MuiTypography-colorInherit {
    font-size: 16px!important;
}

[class*="PrivateBreadcrumbSeparator"] {
    color: white !important;
}

[class*="MuiBreadcrumbs-separator"] {
    color: white !important;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 16px !important;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  .MuiGrid-spacing-xs-3 {
    margin: 0px !important;
    padding: 0px !important;
  }

  @media (max-width: 600px) {

    .heading {
      line-height: 2.36;
    }
  }

  @media only screen and (max-width: 768px) {
    .MuiTypography-body1 {
        font-size: 16px !important;
        line-height: 1.2 !important;
        font-family: 'roboto' !important;
    }
    [class*="Base-main"]{
        padding: 0px !important;
    }
    [class*="Branches-main"]{
        padding:0px !important;
    }
    [class*="App-secondaryBar"]{
        height: 88px!important;
    }
    .jss16 {
        height: 88px!important;
    }
    .mobile-padding {
        padding: 0px !important;
    }
}
.MuiBreadcrumbs-ol{
    margin-left: 1px!important;
    padding-top: 18px!important;
}

.bannerContainer{
    background-color: #f8f6f4;
}

@media only screen and (min-width: 1024px) {
    .bannerContainer{
        width:100vw;
        overflow-x: hidden;
    }
}

.bannerItem {
    border-radius: 4;
    box-shadow: 0 0 8px 0 #d2d5d6;
    background-color: #ffffff;
}


@media only screen and (max-width: 1024px) {
    .bannerItem{
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 24px;
    }
}
@media only screen and (min-width: 1024px) {
    .bannerItem{
        /* height: 118px; */
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .gridContainer {
        padding-top: 24px;
    }
}

@media only screen and (min-width: 1024px) {
    .gridContainer {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

@media only screen and (max-width: 1024px) {
    .desktopButton {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
    .mobileButton {
        display: none;
    }
}


  /* .MuiButtonBase-root:hover .MuiIconButton-root:hover .MuiIconButton-colorInherit:hover .MuiIconButton-edgeEnd:hover {
    background-color: transparent !important;
    background: transparent!important;
    box-shadow: none!important;
    border-radius: 0px;
  } */

  .goback-button {
    width: 152px !important;
    height: 39px !important;
    border-radius: 4px !important;
    background-color: #f8f6f4 !important;
    cursor: pointer;
  }

  .MuiSvgIcon-root {
      height: 26px!important;
      width: 26px!important;
  }

  .checkCircleSvg {
    height: 18px!important;
    width: 18px!important;
  }

  .micIconSvg {
    height: 24px!important;
    width: 40px!important;
  }

  /* .notRec{
        background-color: white;
    }

    .Rec{
        animation-name: pulse;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    } */

    @keyframes pulse{
        0%{
            box-shadow: 0px 0px 5px 0px #B0D3BB;
        }
        65%{
            box-shadow: 0px 0px 5px 13px #B0D3BB;
        }
        90%{
            box-shadow: 0px 0px 5px 13px #B0D3BB;
        }
    }
    
    ._2Gpzx {
        width: 20px;
        margin-right: 0;
        z-index: 1;
        position: relative;
        top: 0;
        padding-left: 6px;
        border-left: 1px solid #989898;
        outline: none;
        cursor: pointer;
        margin-left: -2em
    }
    
    ._3e6oA {
        position: absolute;
        left: 42%;
        top: -3em;
        text-align: center;
        z-index: 1
    }
    
    .yToIp {
        margin-top: 0;
        width: 18px;
        margin-right: 0;
        z-index: 1;
        position: relative;
        left: -27px;
        border-radius: 12px;
        border: 1px solid #fff;
        -webkit-animation: _1zVhP 1s infinite;
        animation: _1zVhP 1s infinite;
        -webkit-animation-delay: 0ms;
        animation-delay: 0ms
    }
    
    .sdAcB {
        width: 8em;
        padding: 19px 32px
    }
    
    .i_TF6 {
        left: 2.5em;
        top: 1.5em;
        width: 3em;
        position: absolute;
        border-radius: 50%;
        z-index: 2
    }
    
    ._2hDUz {
        width: 31px;
        -webkit-animation: _1zVhP 1s infinite;
        animation: _1zVhP 1s infinite
    }
    
    ._2hDUz,
    ._2ntLM {
        float: left;
        position: absolute;
        bottom: 19px;
        left: 20px;
        border-radius: 50%
    }
    
    ._2ntLM {
        width: 34px
    }
    
    ._3Ba4j {
        left: 48.3%;
        top: 5.9em;
        width: 40px;
        height: 40px
    }
    
    ._3Ba4j,
    ._3N8we {
        border: 7px solid #00c689;
        border-radius: 100%;
        position: absolute;
        -webkit-animation: _2OA8F 2s ease-out;
        animation: _2OA8F 2s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite
    }
    
    ._3N8we {
        left: -9%;
        top: -3px;
        width: 31px;
        height: 31px
    }
    
    @-webkit-keyframes _2OA8F {
        0% {
            -webkit-transform: scale(1);
            opacity: 0
        }
        1% {
            -webkit-transform: scale(1.1);
            opacity: 1
        }
        99% {
            -webkit-transform: scale(1.9);
            opacity: .01
        }
        to {
            -webkit-transform: scale(2);
            opacity: 0
        }
    }
    
    @keyframes _2OA8F {
        0% {
            -webkit-transform: scale(1);
            opacity: 0
        }
        1% {
            -webkit-transform: scale(1.1);
            opacity: 1
        }
        99% {
            -webkit-transform: scale(1.9);
            opacity: .01
        }
        to {
            -webkit-transform: scale(2);
            opacity: 0
        }
    }
    
    @-webkit-keyframes _1zVhP {
        0% {
            -webkit-box-shadow: 0 0 -10px #00c689;
            box-shadow: 0 0 -10px #00c689
        }
        40% {
            -webkit-box-shadow: 0 0 35px 6px #00c689;
            box-shadow: 0 0 35px 6px #00c689
        }
        60% {
            -webkit-box-shadow: 0 0 35px 6px #00c689;
            box-shadow: 0 0 35px 6px #00c689
        }
        to {
            -webkit-box-shadow: 0 0 -10px #00c689;
            box-shadow: 0 0 -10px #00c689
        }
    }
    
    @keyframes _1zVhP {
        0% {
            -webkit-box-shadow: 0 0 -10px #00c689;
            box-shadow: 0 0 -10px #00c689
        }
        40% {
            -webkit-box-shadow: 0 0 35px 6px #00c689;
            box-shadow: 0 0 35px 6px #00c689
        }
        60% {
            -webkit-box-shadow: 0 0 35px 6px #00c689;
            box-shadow: 0 0 35px 6px #00c689
        }
        to {
            -webkit-box-shadow: 0 0 -10px #00c689;
            box-shadow: 0 0 -10px #00c689
        }
    }
    
    ._6B6rO {
        left: 43.7%;
        top: 10.7em;
        width: 40px;
        position: absolute;
        opacity: .6;
        height: 40px;
        -webkit-animation: eElOc 2s linear;
        animation: eElOc 2s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        z-index: 100
    }
    
    ._2_X-l,
    ._6B6rO {
        border: 6px solid #00c689;
        border-radius: 100%
    }
    
    ._2_X-l {
        left: -16%;
        top: -4.1px;
        width: 36px;
        position: relative;
        opacity: .5;
        height: 36px;
        -webkit-animation: _3FUXM 2s linear;
        animation: _3FUXM 2s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite
    }
    
    @-webkit-keyframes _3FUXM {
        0% {
            -webkit-transform: scale(1.5)
        }
        25% {
            -webkit-transform: scale(1.35)
        }
        50% {
            -webkit-transform: scale(1.5)
        }
        75% {
            -webkit-transform: scale(1.35)
        }
        to {
            -webkit-transform: scale(1.5)
        }
    }
    
    @keyframes _3FUXM {
        0% {
            -webkit-transform: scale(1.5)
        }
        25% {
            -webkit-transform: scale(1.35)
        }
        50% {
            -webkit-transform: scale(1.5)
        }
        75% {
            -webkit-transform: scale(1.35)
        }
        to {
            -webkit-transform: scale(1.5)
        }
    }
    
    @-webkit-keyframes eElOc {
        0% {
            -webkit-transform: scale(1.3)
        }
        25% {
            -webkit-transform: scale(1.6)
        }
        50% {
            -webkit-transform: scale(2.2)
        }
        75% {
            -webkit-transform: scale(1.6)
        }
        to {
            -webkit-transform: scale(1.3)
        }
    }
    
    @keyframes eElOc {
        0% {
            -webkit-transform: scale(1.3)
        }
        25% {
            -webkit-transform: scale(1.6)
        }
        50% {
            -webkit-transform: scale(2.2)
        }
        75% {
            -webkit-transform: scale(1.6)
        }
        to {
            -webkit-transform: scale(1.3)
        }
    }
    
    .Mu636 {
        font-size: 22px;
        margin: 0 auto .6em
    }
    
    ._2tGNh {
        width: 5em;
        margin: 1.2em auto -.1em;
        -webkit-animation: _1zVhP 1s infinite;
        animation: _1zVhP 1s infinite;
        border-radius: 50%
    }
    
    ._2GfcT {
        font-size: 1.2em;
        font-weight: 400;
        text-align: center
    }
    
    ._3mFVY {
        display: block;
        background: #000;
        height: 100vh;
        position: fixed;
        z-index: 3;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .5)
    }
    
    ._1fMKo {
        float: right;
        color: #5c5c5c;
        position: absolute;
        right: .5em;
        top: 0;
        font-size: 1.6em
    }
    
    ._29_yr {
        background: #fff;
        position: fixed;
        border-radius: 8px;
        padding: 1em;
        margin: 1em;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        word-break: break-all;
        top: 9em;
        z-index: 3;
        -ms-flex-direction: column;
        flex-direction: column;
        color: #5c5c5c;
        width: inherit;
        text-align: center;
        margin: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box
    }
    
    @media (max-width:768px) {
        ._3e6oA {
            left: 40%
        }
        .yToIp {
            width: 15px;
            margin-right: 0;
            z-index: 1;
            position: relative;
            margin-left: -24.1px;
            margin-top: 1px;
            left: -3px
        }
        ._3Ba4j {
            left: 45%
        }
    }
    
    @media (max-width:508px) {
        ._3e6oA {
            left: 34%
        }
        ._29_yr {
            margin: 1em;
            left: 0;
            width: -webkit-fill-available
        }
        ._6B6rO {
            left: 44.1%;
            top: 10.7em;
        }
    }
    
    @media (max-width:380px) {
        ._3e6oA {
            left: 30.6%
        }
        ._6B6rO {
            left: 44%;
            top: 10.7em;
        }
    }
