.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.linkLineWrapper {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

body .common-button {
  padding: 8px 16px 10px;
  border-radius: 24px;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

body .common-button-with-icon {
  padding: 4.5px 16px;
}

body .course-sub-header {
  font-size: 1rem;
  font-weight: 600;
}

body .section-heading {
  font-size: 20px;
  font-weight: 700;
}

body .see-more {
  color: #00a465;
  font-size: 14px;
  cursor: pointer;
}

.site-wrapper {
  max-width: 1140px;
  width: 100%;
  padding: 0 24px 24px;
  margin: 0 auto;
}

.full-width-site-wrapper {
  width: 100%;
}

.category-course-container {
  position: relative;
}

.loader-container {
  text-align: center;
}

.loader-container span {
  display: block;
  font-weight: bold;
}

/* Transition page loader */
.transition-page-loader {
  position: fixed;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.transition-page-loader figure {
  width: 172px;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.transition-page-loader figure img {
  width: 100%;
  display: block;
}

.transition-page-loader svg {
  width: 93px;
  height: 67px;
  margin: 0 auto;
  display: block;
  animation: fadeInFromNone 2.5s ease-out infinite;
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  50% {
      display: block;
      opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

/*Admin panel draft notification*/
.draft-pill {
  padding: 4px 14px;
  border-radius: 10px;
  margin: 0 10px 2px 0;
  background: #ccc;
  color: #000;
  font-style: italic;
  display: inline-block;
  text-align: center;
}

@media screen and (max-width: 995px) {

  body .course-sub-header {
    font-size: 14px;
    line-height: 16px;
  }

  body .section-heading {
    font-size: 16px;
    line-height: 1;
  }

  body .subscribed-course-text {
    font-size: 10px;
  }

  body .see-more {
    font-size: 12px !important;
    /*Need to use important due to file style.css using important for the same element*/
  }

  body .see-more img {
    width: 8px;
    height: 8px;
  }

  body .flashcard-sub-header {
    /*Need to use important due to file style.css using important for the same element*/
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

@media screen and (max-width: 540px) { 
  .site-wrapper {
    padding: 0 16px 24px;
  }
}
