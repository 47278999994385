.MuiButton-containedPrimary {
    background-color: #272929 !important;
}

/* [class*="CoursePage-main"] {
    max-width: 1140px;
    align-self: center;
    padding-left: 0!important;
    padding-right: 0!important;
}


[class*="Branches-main"] {
    max-width: 1140px;
    align-self: center;
} */

/* .App-secondaryBar-16 {
    align-self: center;
    padding-left: 0!important;
    padding-right: 0!important;
} */