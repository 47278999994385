
  .MuiPaper-elevation1{
    box-shadow: none !important;
  }

  .list-gutters {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }

  .MuiExpansionPanelDetails-root {
      padding: 0 !important;
  }

  .MuiExpansionPanelSummary-root{
      padding: 0 !important;
  }

  .MuiExpansionPanel-root.Mui-expanded::before{
      opacity: 1 !important;
      top: -8px !important;
  }

  .trans-icon{
    flex-basis: 8.666667%!important;
  }

  .MuiExpansionPanel-root:before {
    opacity: 1 !important;
    /* top: -2px !important; */
    margin-top: -6px !important;
  }



  .CoursePage-heading-215 {
    flex-basis: 88.33% !important;
  }

  .CoursePage-main-181 {
    padding-top: 40px!important;
  }

  .MuiListItemText-root {
    margin-top: 0!important;
    margin-bottom: 0!important;
    flex-basis: 40.33% !important;
  }

  .appbar {
    color: #272929 !important;
    background-color: #f8f6f4 !important;
  }

  .purchase-div {
    width: 50%;
    margin: auto;
    padding: 12px;
    text-align: center;
  }

/* @media only screen and (max-width: 768px) {
  .MuiSvgIcon-root{
    height: 19px!important;
  }
} */

@media only screen and (max-width: 328px) {
    .CoursePage-main-181{
        margin: -15px;
    }
    .MuiTabs-indicator {
        background-color: #272929 !important;
    }
  }

  
  .MuiList-padding {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }

  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: auto!important;
  }

  .MuiListItem-root{
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }

  @media (min-width: 600px) {
    [class*="CoursePage-main"] {
      padding-top: 40px !important;
    }

    [class*="jss184"] :not(.MuiLinearProgress-root) {
      padding-top: 40px !important;
    }
  }
  
  @media (max-width: 600px) {
    
    [class*="CoursePage-main"] {
      padding: 0px !important;
    }

    [class*="jss184"] {
      padding: 0px !important;
    }

    [class*="jss864"] {
      padding: 0px !important;
    }

    [class*="jss631"] {
      padding: 0px !important;
    }

    [class*="jss703"] {
      padding: 0px !important;
    }

    [class*="jss636"] {
      padding: 0px !important;
    }

    [class*="mobile-list"] {
      padding: 16px 16px !important;
    }
  }

  .course-list-header {
    /* position: absolute; */
    font-style: normal;
    font-family: roboto;
    font-weight: 700;
    font-size: 40px;
    max-width: 548px;
    height: 129px;
    display: flex;
    align-items: center;
    border-radius:0px 0px 123px 0px;
    background: #007D64;
    color: #FFFFFF;
    margin-bottom: 1em;
  }

  .continue-div {
    background: #00A4650F;
    border-radius: 6px;
    margin-top: 2em;
    padding: 1.5em 1em;
  }

  @media screen and (max-width: 780px) {
    .course-list-header {
      font-size: 20px;
      height: 58.19px;
      width: 300px;
    }
    .cardLayout {
      width: 50px;
    }
  }

  .course-list-tile {
    margin-left:20px;
    margin-bottom:24px;
  }
  .seeMore {
    cursor: pointer;
    font-size: 14px;
    text-align: right;
    padding: 0 3em 0 0;
    color: #00A465;
}
.seeMoreDiv {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .more-course-cta-container {
    margin-left: auto;
  }

  @media screen and (max-width: 780px) {
    .course-list-tile {
      margin-left: 16px;
    }
    .seeMore {
      padding-right: 1em;
    }

    .course-ratings-container svg {
      /* to override the module styling that uses important*/
      width: 15px !important;
      height: 15px !important;
    }

    .course-rating-list {
      /*to override the inline styling*/
      margin-left: 0 !important;
    }

    .course-rating-number {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: -12px;
    }
  }

  @media screen and (max-width: 995px) {
    .lp-compliance-section-cotainer {
      min-height: 353px;
    }
  }
